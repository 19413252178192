'use client';

import { Box, Typography, styled } from '@mui/material';

import PreliminaryDataSvg from './svg/preliminary.svg';
import ChosenPeriodSvg from './svg/chosen.svg';
import PreviousPeriodSvg from './svg/previous.svg';

export const ContentHeaderBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	padding: theme.spacing(1),
	borderBottom: `1px solid ${theme.palette.custom.table.border}`,
	marginTop: theme.spacing(2),
})) as typeof Box;

export const DashboardLegendBox = styled(Box)(() => ({
	display: 'flex',
}));

export const DashboardLegendItemBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 2),
	gap: theme.spacing(1),
}));

export const DashboardLegendItemTypography = styled(Typography)(({ theme }) => ({
	...theme.typography.subtitle2,
	color: theme.palette.custom.component.placeholder,
}));

export const PreliminaryDataIcon = styled(PreliminaryDataSvg)(({ theme }) => ({
	color: theme.palette.secondary.main,
}));
export const ChosenPeriodIcon = styled(ChosenPeriodSvg)(({ theme }) => ({
	color: theme.palette.secondary.main,
}));
export const PreviousPeriodIcon = styled(PreviousPeriodSvg)(({ theme }) => ({
	color: theme.palette.secondary.light,
}));

export const GroupingBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	minHeight: theme.spacing(6),
}));
