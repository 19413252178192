import { type TChartDashboardPoint } from '../../api';

export const replaceLeadingNullsAndFlattenPoints = (list: (TChartDashboardPoint | null)[]) => {
	const firstNonNullIndex = list.findIndex((point) => point !== null);

	return list.map((point, index) => {
		if (index < firstNonNullIndex || firstNonNullIndex === -1 || point === null) {
			return 0;
		}
		return point.value;
	});
};
