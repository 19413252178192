import { makeApi, parametersBuilder } from '@zodios/core';

import { dashboardResponseSchema, dashboardRequestSchema } from './schemas';

const dashboardParams = parametersBuilder().addQueries(dashboardRequestSchema).build();
export const dashboardApi = makeApi([
	{
		alias: 'getDashboard',
		method: 'get',
		path: '/cabinet/analytics/dashboard/:groupType/',
		response: dashboardResponseSchema,
		parameters: dashboardParams,
	},
]);
