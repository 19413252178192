import { type TGroupingType } from '@features/select-grouping-type';
import { type TDateRangeValue, getPreviousDateRange } from '@shared/components/date-range-calendar';
import { dashboardApiHooks } from '@widgets/affiliate-dashboard/api';
import { mapGroupTypeToPathParam } from '@widgets/affiliate-dashboard/lib/map-group-type-to-path-param';
import { mapDateToBackend } from '@shared/mappers/map-date-to-backend';

export const useFetchDashboardData = (
	dateRange: TDateRangeValue,
	groupingType: TGroupingType[],
) => {
	const previousDateRange = getPreviousDateRange(dateRange);

	const currentData = dashboardApiHooks.useGetDashboard(
		{
			params: {
				groupType: mapGroupTypeToPathParam(groupingType[0]),
			},
			queries: {
				dateFrom: mapDateToBackend(dateRange[0] ?? new Date().toISOString()),
				dateTo: mapDateToBackend(dateRange[1] ?? new Date().toISOString()),
			},
		},
		{
			enabled: !dateRange.includes(null),
		},
	);

	const previousData = dashboardApiHooks.useGetDashboard(
		{
			params: {
				groupType: mapGroupTypeToPathParam(groupingType[0]),
			},
			queries: {
				dateFrom: mapDateToBackend(previousDateRange[0] ?? new Date().toISOString()),
				dateTo: mapDateToBackend(previousDateRange[1] ?? new Date().toISOString()),
			},
		},
		{
			enabled: !previousDateRange.includes(null),
		},
	);

	const isLoading = currentData.isLoading || previousData.isLoading;
	const isError = currentData.isError || previousData.isError;
	const isSuccess = currentData.isSuccess && previousData.isSuccess;
	return { isLoading, isError, isSuccess, currentData, previousData };
};
