import { useMemo } from 'react';
import { differenceInDays, startOfDay, endOfDay, formatISO } from 'date-fns';

import { type TGroupingType } from '@features/select-grouping-type';
import { getPreviousDateRange, type TDateRangeValue } from '@shared/components/date-range-calendar';

import {
	type TDashboardsResponse,
	type TChartName,
	type TChartType,
	type TChartDashboardPoint,
} from '../../api';

import { getPartialTriggerIntervalIndex } from './get-partial-trigger-interval-index';
import { replaceLeadingNullsAndFlattenPoints } from './replace-leading-nulls-and-flatten-points';
import { replaceAllNullWithZeroIfOnlyNull } from './replace-all-null-with-zero-if-only-null';
import { getChartTitle } from './get-chart-title';
import { getChartDateIntervalFromRange } from './get-chart-date-interval-from-range';
import { mapPointsToInterval } from './map-points-to-interval';

const preparePointsData = (points: (TChartDashboardPoint | null)[]) =>
	replaceAllNullWithZeroIfOnlyNull(replaceLeadingNullsAndFlattenPoints(points));

interface TUseDashboardChartDataPayload {
	charts?: TDashboardsResponse['charts'];
	previousPeriodCharts?: TDashboardsResponse['charts'];
	groupingType: TGroupingType;
	dateRange?: TDateRangeValue;
	previousDateRange?: TDateRangeValue;
}
export const useDashboardChartData = ({
	charts,
	previousPeriodCharts,
	dateRange,
	groupingType,
}: TUseDashboardChartDataPayload) =>
	useMemo(() => {
		if (
			typeof charts === 'undefined' ||
			typeof previousPeriodCharts === 'undefined' ||
			typeof dateRange === 'undefined'
		) {
			return [];
		}
		const previousDateRange = getPreviousDateRange(dateRange);

		if (previousDateRange[0] === null || dateRange[0] === null) {
			return [];
		}

		const daysDifference = differenceInDays(dateRange[0], previousDateRange[0]);
		let dateChartInterval = getChartDateIntervalFromRange(dateRange, groupingType);
		if (typeof dateChartInterval === 'undefined') {
			return [];
		}
		// !NB hack for one-day chart
		if (dateChartInterval.length === 1 && dateRange[0] === dateRange[1]) {
			dateChartInterval = [formatISO(startOfDay(dateRange[0])), formatISO(endOfDay(dateRange[0]))];
		}

		return (Object.entries(charts) as [TChartName, TChartType][]).map(
			([chartName, { type, summary, points }]) => {
				const { previous, current } = mapPointsToInterval({
					interval: dateChartInterval ?? [],
					prevPoints: previousPeriodCharts[chartName]?.points,
					points,
					groupingType,
					daysDifference,
				});
				const partialTriggerIntervalIndex = getPartialTriggerIntervalIndex(current);

				return {
					title: getChartTitle(chartName),
					chartMeta: {
						current: { type, value: summary },
						previous: previousPeriodCharts
							? { type, value: previousPeriodCharts[chartName].summary }
							: undefined,
					},
					dateChartInterval: dateChartInterval ?? [],
					pointsSeries: {
						previous: preparePointsData(previous),
						current: preparePointsData(current),
					},
					periodsDiffInDays: daysDifference,
					partialTriggerIntervalValue: partialTriggerIntervalIndex
						? dateChartInterval?.[partialTriggerIntervalIndex]
						: undefined,
				};
			},
		);
	}, [charts, previousPeriodCharts, dateRange, groupingType]);
