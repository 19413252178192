'use client';

import React from 'react';

import { useRole } from '@entities/roles';
import { PageTitle } from '@shared/components/page-title';
import { ContentWrapper } from '@shared/components/content-wrapper';
import { AffiliateDashboard } from '@widgets/affiliate-dashboard';
import { MarketingGeneralReport } from '@widgets/marketing-general-report';
import { OverviewReport } from '@widgets/overview-report';
import { type TUserRole, userRole } from '@shared/types';

const getPageContent = (role: TUserRole) => {
	const roleToComponentMap: Record<TUserRole, React.ReactNode> = {
		[userRole.marketer]: <MarketingGeneralReport />,
		[userRole.marketerVip]: <MarketingGeneralReport />,
		[userRole.affiliate]: <AffiliateDashboard />,
		[userRole.manager]: <OverviewReport />,
	};

	return roleToComponentMap[role];
};

export const MainPage: React.FC = () => {
	const { role, isMarketer } = useRole();

	if (!role) {
		return null;
	}

	return (
		<>
			<PageTitle>{isMarketer ? 'Reports' : 'Dashboard'}</PageTitle>
			<ContentWrapper>{getPageContent(role)}</ContentWrapper>
		</>
	);
};
