import { type TChartDashboardPoint } from '../../api';

type TChartDashboardPoints = (TChartDashboardPoint | null)[];

export const getPartialTriggerIntervalIndex = (points: TChartDashboardPoints) => {
	const lastPartialDataIndex = points.findLastIndex((item) => item && item.isPartialData);

	if (lastPartialDataIndex === -1) {
		return undefined;
	}

	/**
	 * Starting array index of slice with last three days
	 */
	return points.findLastIndex((item, index) => item && index < lastPartialDataIndex - 1);
};
