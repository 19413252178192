import { formatISO, subWeeks } from 'date-fns';

import { type TDateRangeValue } from '@shared/components/date-range-calendar';
import { type TReportSorting } from '@shared/components/report-table/types';

import { type TGeneralMarketingReportData, type TGeneralMarketingReportTotals } from '../api/types';
import { marketingGeneralReportResponseSchema } from '../api/schemas';

import { type TGeneralMarketingReportColumn, type TGeneralMarketingReportColumnId } from './types';

export const generalMarketingReportDefaultDateRange: TDateRangeValue = [
	formatISO(subWeeks(new Date(), 1)),
	formatISO(new Date()),
];

export const generalMarketingReportDefaultData: TGeneralMarketingReportData = [];

export const generalMarketingReportDefaultTotals: TGeneralMarketingReportTotals =
	marketingGeneralReportResponseSchema.shape.totals.parse({});

export const generalMarketingReportDefaultSortingOrder = Object.fromEntries(
	Object.entries(marketingGeneralReportResponseSchema.shape.data.element.shape).map(([key]) => [
		key,
		'none',
	]),
) as TReportSorting<TGeneralMarketingReportColumnId>;

export const generalMarketingReportDefaultSortingType = 'soldDate';

export const generalMarketingReportTableColumns: TGeneralMarketingReportColumn[] = [
	{
		id: 'aid',
		label: 'AID',
		minWidth: 350,
		align: 'left',
		needSorting: true,
		pinned: true,
		pinOffset: 0,
	},
	{ id: 'date', label: 'Date', width: 'auto', align: 'left', pinned: true, pinOffset: 350 },
	{
		id: 'visitsCount',
		label: 'Visits',
		width: 'auto',
		align: 'left',
	},
	{
		id: 'visitLeadConversion',
		label: 'CR, %',
		minWidth: 200,
		align: 'right',
	},
	{
		id: 'leadsCount',
		label: 'Leads',
		minWidth: 200,
		align: 'right',
	},
	{
		id: 'leadClickConversion',
		label: 'CR, %',
		minWidth: 200,
		align: 'right',
	},
	{
		id: 'clicksCount',
		label: 'Listing Clicks',
		minWidth: 200,
		align: 'right',
	},
	{
		id: 'revenue',
		label: 'Revenue',
		minWidth: 200,
		align: 'right',
	},
	{
		id: 'epl',
		label: 'EPL, $',
		minWidth: 200,
		align: 'right',
	},
	{
		id: 'epc',
		label: 'EPC, $',
		minWidth: 200,
		align: 'right',
	},
];
