import { type TRowData } from '@shared/components/report-table/types';
import { getAmountFromCents } from '@shared/utils/number';

import { type TGeneralMarketingReportTotals } from '../api/types';
import { type TGeneralMarketingReportColumnId } from '../model';

export const mapTotalsFromBackend = (
	totals: TGeneralMarketingReportTotals,
): TRowData<TGeneralMarketingReportColumnId> => ({
	id: 'totals',
	cells: {
		aid: {
			value: 'Totals',
		},
		date: { value: '' },
		visitsCount: {
			value: String(totals.visitsCount),
		},
		visitLeadConversion: {
			value: `${totals.visitLeadConversion} %`,
		},
		leadsCount: {
			value: String(totals.leadsCount),
		},
		leadClickConversion: {
			value: `${totals.leadClickConversion} %`,
		},
		clicksCount: {
			value: String(totals.clicksCount),
		},
		revenue: {
			value: String(totals.revenue),
		},
		epl: {
			value: getAmountFromCents(totals.epl).toFixed(2),
		},
		epc: {
			value: getAmountFromCents(totals.epc).toFixed(2),
		},
	},
	isWeekend: false,
	isPartialData: false,
});
