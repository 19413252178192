import { type TRowData } from '@shared/components/report-table/types';
import { getAmountFromCents } from '@shared/utils/number';

import { type TGeneralMarketingReportData } from '../api/types';
import { type TGeneralMarketingReportColumnId } from '../model';

import { mapDateFromBackend } from './map-date-from-backend';

export const mapDataToCellData = (
	data: TGeneralMarketingReportData,
): TRowData<TGeneralMarketingReportColumnId>[] =>
	data.map((rowData) => ({
		id: `${rowData.aid}_${rowData.date}`,
		cells: {
			aid: {
				value: String(rowData.aid),
			},
			date: {
				value: mapDateFromBackend(rowData.date),
			},
			visitsCount: {
				value: String(rowData.visitsCount),
			},
			visitLeadConversion: {
				value: `${rowData.visitLeadConversion} %`,
			},
			leadsCount: {
				value: String(rowData.leadsCount),
			},
			leadClickConversion: {
				value: `${rowData.leadClickConversion} %`,
			},
			clicksCount: {
				value: String(rowData.clicksCount),
			},
			revenue: {
				value: String(rowData.revenue),
			},
			epl: {
				value: getAmountFromCents(rowData.epl).toFixed(2),
			},
			epc: {
				value: getAmountFromCents(rowData.epc).toFixed(2),
			},
		},
	}));
