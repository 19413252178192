import { ListItemText, List, ListItem, styled, Typography } from '@mui/material';

import ChosenPeriodSvg from './svg/chosen.svg';
import PreviousPeriodSvg from './svg/previous.svg';

export const CustomTooltipTitle = styled(Typography)(({ theme }) => ({
	...theme.typography.subtitle2,
	padding: theme.spacing(1.5),
	textAlign: 'left',
})) as typeof Typography;

export const CustomTooltipListItemText = styled(ListItemText)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: theme.spacing(1.5),
}));

export const CustomTooltipList = styled(List)(({ theme }) => ({
	padding: theme.spacing(1.5),
	gap: theme.spacing(0.5),
}));
export const CustomTooltipListItem = styled(ListItem)(({ theme }) => ({
	padding: theme.spacing(0),
}));

export const CustomTooltipDateLabel = styled(Typography)(({ theme }) => ({
	...theme.typography.chartTooltipLabel,
	textAlign: 'right',
	flexGrow: 1,
})) as typeof Typography;

export const CustomTooltipValueLabel = styled(Typography)(({ theme }) => ({
	...theme.typography.subtitle2,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-start',
	gap: theme.spacing(0.5),
	flexGrow: 1,
})) as typeof Typography;

export const ChosenPeriodIcon = styled(ChosenPeriodSvg)(({ theme }) => ({
	color: theme.palette.secondary.main,
}));
export const PreviousPeriodIcon = styled(PreviousPeriodSvg)(({ theme }) => ({
	color: theme.palette.secondary.light,
}));
