import Stack from '@mui/material/Stack';

import { DashboardChart, DashboardChartSkeleton } from '@entities/dashboard-chart';
import { type TDateRangeValue } from '@shared/components/date-range-calendar';
import { type TGroupingType } from '@features/select-grouping-type';

import { useDashboardChartData, useFetchDashboardData } from '../lib';

interface TDashboardChartsProps {
	dateRange: TDateRangeValue;
	groupingType: TGroupingType[];
}
export const DashboardCharts: React.FC<TDashboardChartsProps> = ({ dateRange, groupingType }) => {
	const { isSuccess, currentData, previousData } = useFetchDashboardData(dateRange, groupingType);
	const chartsData = useDashboardChartData({
		charts: currentData?.data?.charts,
		previousPeriodCharts: previousData?.data?.charts,
		dateRange,
		groupingType: groupingType[0],
	});
	// TODO избавиться от groupingType[0]

	return (
		<Stack direction="row">
			{isSuccess && chartsData.length ? (
				chartsData.map(
					({
						title,
						chartMeta,
						pointsSeries,
						dateChartInterval,
						partialTriggerIntervalValue,
						periodsDiffInDays,
					}) => (
						<DashboardChart
							key={title}
							title={title}
							chartMeta={chartMeta}
							dateChartInterval={dateChartInterval}
							points={pointsSeries}
							groupingType={groupingType[0]}
							partialTriggerIntervalValue={partialTriggerIntervalValue}
							periodsDiffInDays={periodsDiffInDays}
						/>
					),
				)
			) : (
				<>
					<DashboardChartSkeleton />
					<DashboardChartSkeleton />
					<DashboardChartSkeleton />
				</>
			)}
		</Stack>
	);
};
