import { type SxProps, type Theme } from '@mui/material';
import {
	chartsAxisHighlightClasses,
	lineHighlightElementClasses,
	axisClasses,
	chartsGridClasses,
} from '@mui/x-charts';

export const rootChartContainerSX: SxProps<Theme> = (theme) => ({
	[`&& .${chartsAxisHighlightClasses.root}`]: {
		stroke: '#DEDAE2',
	},
	[`&& .${axisClasses.line}, && .${axisClasses.tick}`]: {
		stroke: '#E5E1E9',
	},
	[`&& .${chartsGridClasses.horizontalLine}`]: {
		stroke: '#FAF6FE',
	},
	[`&& .${axisClasses.tickLabel}`]: {
		fontFamily: theme.typography.fontFamily,
		fill: '#BBB7C0',
		fontSize: theme.typography.pxToRem(12),
		fontWeight: 500,
	},
	[`&& .${lineHighlightElementClasses.root}`]: {
		fill: theme.palette.secondary.main,
	},
});
