import { isSameDay, isSameWeek, isSameMonth } from 'date-fns';

import { type TGroupingType } from '@features/select-grouping-type';

export const getCompareFunc = (grouping: TGroupingType): ((d1: Date, d2: Date) => boolean) => {
	switch (grouping) {
		case 'weekly':
			return (d1, d2) => isSameWeek(d1, d2, { weekStartsOn: 0 });
		case 'monthly':
			return isSameMonth;
		case 'daily':
		default:
			return isSameDay;
	}
};
