import React, { useCallback, useMemo } from 'react';
import {
	ChartsAxisHighlight,
	ChartsGrid,
	ChartsTooltip,
	type ChartsTooltipSlots,
	ChartsXAxis,
	type LineElementSlots,
	LineHighlightPlot,
	LinePlot,
	MarkPlot,
	ResponsiveChartContainer,
} from '@mui/x-charts';
import { useTheme } from '@mui/material/styles';
import { getUnixTime, secondsToMilliseconds } from 'date-fns';

import { type TLineChartAxisProps, type TLineChartSeriesProps } from '../model';
import { currentPeriodSeriesId, previousPeriodSeriesId } from '../lib';

import { rootChartContainerSX } from './styles';

const SVG_CHART_MARGIN = {
	left: 20,
	right: 20,
	top: 20,
	bottom: 20,
};

interface TCustomLineChartProps {
	dates?: string[];
	values?: (number | null)[];
	previousValues?: (number | null)[];
	tooltipSlotElement?: ChartsTooltipSlots<'line'>['axisContent'];
	lineSlotElement?: LineElementSlots['line'];
	xAxisValueFormatter: (val: number) => string;
}

export const CustomLineChart: React.FC<TCustomLineChartProps> = ({
	dates,
	values,
	previousValues,
	tooltipSlotElement,
	lineSlotElement,
	xAxisValueFormatter,
}) => {
	const theme = useTheme();

	const tickInterval = useCallback(
		(value: string, index: number) => {
			if (!dates) {
				return false;
			}

			return index === 0 || index === dates.length - 1;
		},
		[dates],
	);

	const xAxis = useMemo<TLineChartAxisProps>(
		() => [
			{
				id: 'x-axis',
				scaleType: 'point',
				data: dates?.map((date) => secondsToMilliseconds(getUnixTime(date))),
				valueFormatter: xAxisValueFormatter,
				tickMaxStep: 1,
				tickMinStep: 1,
				tickInterval,
			},
		],
		[dates, tickInterval, xAxisValueFormatter],
	);

	const series = useMemo<TLineChartSeriesProps>(
		() => [
			{
				id: previousPeriodSeriesId,
				type: 'line',
				data: previousValues || [],
				showMark: false,
				curve: 'linear',
				color: theme.palette.secondary.light,
				connectNulls: true,
			},
			{
				id: currentPeriodSeriesId,
				type: 'line',
				data: values,
				showMark: false,
				curve: 'linear',
				color: theme.palette.secondary.main,
				connectNulls: true,
			},
		],
		[theme, values, previousValues],
	);

	return (
		<ResponsiveChartContainer
			xAxis={xAxis}
			series={series}
			margin={SVG_CHART_MARGIN}
			sx={rootChartContainerSX}
		>
			<ChartsXAxis />
			<ChartsAxisHighlight x="line" />
			<ChartsGrid horizontal />
			<LinePlot slots={{ line: lineSlotElement }} />
			<LineHighlightPlot />
			<MarkPlot />
			<ChartsTooltip trigger="axis" slots={{ axisContent: tooltipSlotElement }} />
		</ResponsiveChartContainer>
	);
};
