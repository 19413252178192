import z from 'zod';
import { makeParameters } from '@zodios/core';

export const marketingGeneralReportParams = makeParameters([
	{
		name: 'dateFrom',
		type: 'Query',
		schema: z.string().datetime({ offset: true }),
	},
	{
		name: 'dateTo',
		type: 'Query',
		schema: z.string().datetime({ offset: true }),
	},
	{
		name: 'source',
		type: 'Query',
		schema: z.array(z.string()).optional(),
	},
	{
		name: 'utmSource',
		type: 'Query',
		schema: z.array(z.string()).optional(),
	},
	{
		name: 'utmMedium',
		type: 'Query',
		schema: z.array(z.string()).optional(),
	},
	{
		name: 'utmCampaign',
		type: 'Query',
		schema: z.array(z.string()).optional(),
	},
	{
		name: 'utmContents',
		type: 'Query',
		schema: z.array(z.string()).optional(),
	},
]);

export const marketingGeneralReportResponseSchema = z.object({
	data: z.array(
		z.object({
			aid: z.number(),
			date: z.string().datetime({ offset: true }),
			visitsCount: z.number(),
			visitLeadConversion: z.number(),
			leadsCount: z.number(),
			leadClickConversion: z.number(),
			clicksCount: z.number(),
			revenue: z.number(),
			epl: z.number(),
			epc: z.number(),
		}),
	),
	filter: z.object({
		source: z.array(z.string()),
		utmSource: z.array(z.string()),
		utmMedium: z.array(z.string()),
		utmCampaign: z.array(z.string()),
		utmContent: z.array(z.string()),
	}),
	totals: z.object({
		visitsCount: z.number().default(0),
		visitLeadConversion: z.number().default(0),
		leadsCount: z.number().default(0),
		leadClickConversion: z.number().default(0),
		clicksCount: z.number().default(0),
		revenue: z.number().default(0),
		epl: z.number().default(0),
		epc: z.number().default(0),
	}),
});

export const marketingGeneralReportErrorResponseSchema = z.object({
	message: z.string(),
});
