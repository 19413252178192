import { useEffect } from 'react';

import { DropdownSelect } from '@shared/components/dropdown-select';
import { type TDateRangeValue } from '@shared/components/date-range-calendar';

import { type TGroupingType } from '../model';
import { useGroupingTypeOptionsList } from '../lib';

import { RootBox } from './styles';

interface TSelectGroupingTypeProps {
	onSelect: (selectedValue: TGroupingType[]) => void;
	groupingTypeValue: TGroupingType[];
	selectedDateRange: TDateRangeValue;
}
export const SelectGroupingType: React.FC<TSelectGroupingTypeProps> = ({
	onSelect,
	groupingTypeValue,
	selectedDateRange,
}) => {
	const { optionsList, isOptionsListNewRef } = useGroupingTypeOptionsList(selectedDateRange);

	useEffect(() => {
		if (!isOptionsListNewRef.current) {
			return;
		}
		const optionsListValues = optionsList.map(({ value }) => value);
		const filteredValueByNewOptions = groupingTypeValue.filter((value) =>
			optionsListValues.includes(value),
		);
		// set filtered current value or set first type from new options list
		onSelect(filteredValueByNewOptions.length ? filteredValueByNewOptions : [optionsList[0].value]);
		isOptionsListNewRef.current = false;
	}, [groupingTypeValue, isOptionsListNewRef, onSelect, optionsList, selectedDateRange]);
	return (
		<RootBox>
			<DropdownSelect
				disabled={selectedDateRange.includes(null)}
				onDropdownSelect={onSelect}
				dropdownValue={groupingTypeValue}
				options={optionsList}
			/>
		</RootBox>
	);
};
