import {
	eachDayOfInterval,
	eachWeekOfInterval,
	eachMonthOfInterval,
	startOfWeek,
	endOfMonth,
	formatISO,
} from 'date-fns';

import { type TDateRangeValue } from '@shared/components/date-range-calendar';
import { type TGroupingType } from '@features/select-grouping-type';

export const getChartDateIntervalFromRange = (
	dateRange: TDateRangeValue,
	groupingType: TGroupingType,
) => {
	const [startDate, endDate] = dateRange;
	if (startDate === null || endDate === null) {
		return undefined;
	}
	switch (groupingType) {
		case 'weekly':
			return eachWeekOfInterval(
				{ start: startDate, end: endDate },
				{ weekStartsOn: 0 }, // The week starts from the sunday
			).map((date) => formatISO(startOfWeek(date, { weekStartsOn: 0 })));

		case 'monthly':
			return eachMonthOfInterval({ start: startDate, end: endDate }).map((date) =>
				formatISO(endOfMonth(date)),
			);
		case 'daily':
		default:
			return eachDayOfInterval({ start: startDate, end: endDate }).map((date) => formatISO(date));
	}
};
