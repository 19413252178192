import { parseISO, addDays } from 'date-fns';

import { type TGroupingType } from '@features/select-grouping-type';

import { type TChartDashboardPoint } from '../../../api';

import { getCompareFunc } from './get-compare-func';
import { findMatchingPoint } from './find-matching-point';

interface TMapPointsToIntervalPayload {
	interval: string[];
	points: TChartDashboardPoint[];
	prevPoints: TChartDashboardPoint[];
	groupingType: TGroupingType;
	daysDifference: number;
}

export const mapPointsToInterval = ({
	interval,
	points,
	prevPoints,
	groupingType,
	daysDifference,
}: TMapPointsToIntervalPayload) => {
	const compareFunction = getCompareFunc(groupingType);

	return interval.reduce<{
		current: (TChartDashboardPoint | null)[];
		previous: (TChartDashboardPoint | null)[];
	}>(
		(acc, date) => {
			const previousDate = addDays(date, -daysDifference);

			const currentPoint = findMatchingPoint(parseISO(date), points, compareFunction);
			const previousPeriodPoint = findMatchingPoint(previousDate, prevPoints, compareFunction);

			return {
				current: [...acc.current, currentPoint ?? null],
				previous: [...acc.previous, previousPeriodPoint ?? null],
			};
		},
		{ current: [], previous: [] },
	);
};
