import { makeApi, makeErrors } from '@zodios/core';

import {
	marketingGeneralReportErrorResponseSchema,
	marketingGeneralReportParams,
	marketingGeneralReportResponseSchema,
} from './schemas';

export const generalMarketingReportApi = makeApi([
	{
		alias: 'getReport',
		method: 'get',
		path: '/cabinet/analytics/report/general-marketing/',
		response: marketingGeneralReportResponseSchema,
		parameters: marketingGeneralReportParams,
		errors: makeErrors([
			{
				status: 'default',
				schema: marketingGeneralReportErrorResponseSchema,
			},
		]),
	},
]);
