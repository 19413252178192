import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

import { LA_TIME_ZONE } from '@shared/utils/date';

export const mapDateFromBackend = (dateString: string) => {
	const pstDate = toZonedTime(dateString, LA_TIME_ZONE);

	return format(pstDate, `MM/dd/yyyy HH:mm`);
};
