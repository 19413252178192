import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import { axiosInstance } from '@shared/api/axios-instance';
import { BASE_URL } from '@shared/api/constants';

import { generalMarketingReportApi } from './api';

const generalMarketingReportClient = new Zodios(BASE_URL, generalMarketingReportApi, {
	axiosInstance,
});

export const generalMarketingReportHooks = new ZodiosHooks(
	'generalMarketingReport',
	generalMarketingReportClient,
);
