import { getFormattedAmount } from '@shared/utils/number';

import { type TChartValueType } from './types';

export const getChartSummaryValue = ({ value, type }: { value: number; type: TChartValueType }) => {
	if (type === 'number') {
		return value;
	}
	const numberValue = getFormattedAmount(Number(value) / 100);
	return `$${numberValue}`;
};
