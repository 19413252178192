'use client';

import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import MuiSkeleton from '@mui/material/Skeleton';
import MuiBox from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const DashboardChartStack = styled(Stack)(({ theme }) => ({
	flexGrow: 1,
	padding: theme.spacing(3),
	gap: theme.spacing(3),
	'&:nth-of-type(even)': {
		borderLeft: `1px solid ${theme.palette.custom.table.border}`,
		borderRight: `1px solid ${theme.palette.custom.table.border}`,
	},
}));

export const DashboardChartTitle = styled(Typography)(({ theme }) => ({
	...theme.typography.subtitle2,
	color: theme.palette.custom.component.placeholder,
	marginBottom: theme.spacing(1),
}));

export const DashboardChartValue = styled(Typography)(({ theme }) => ({
	fontSize: theme.typography.pxToRem(24),
	fontWeight: 500,
	lineHeight: 1.33,
	letterSpacing: '-0.48px',
	color: theme.palette.custom.dark.highest,
	marginBottom: theme.spacing(0.5),
}));

export const DashboardChartPreviousValue = styled(Typography)(({ theme }) => ({
	...theme.typography.subtitle2,
	color: theme.palette.custom.component.placeholder,
}));

export const Skeleton = styled(MuiSkeleton)(({ theme }) => ({
	backgroundColor: theme.palette.custom.component.skeleton,
}));

export const ChartBox = styled(MuiBox)(({ theme }) => ({
	height: theme.typography.pxToRem(250),
}));
