import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import {
	format,
	fromUnixTime,
	millisecondsToSeconds,
	addDays,
	addWeeks,
	addMonths,
} from 'date-fns';

import { CustomLineChart, currentPeriodSeriesId } from '@shared/components/chart';
import {
	getCustomLineSlot,
	CustomAnimatedLine,
} from '@shared/components/custom-chart-animated-line';
import {
	getCustomTooltipSlot,
	CustomChartAxisTooltip,
} from '@shared/components/custom-chart-axis-tooltip';
import { getAmountFromCents, getFormattedAmount } from '@shared/utils/number';

import { getChartSummaryValue, type TChartMeta, type TChartValues } from '../lib';

import {
	DashboardChartStack,
	DashboardChartTitle,
	DashboardChartValue,
	DashboardChartPreviousValue,
	ChartBox,
} from './styles';

const formatDateToString = (date: Date) => format(date, 'LLL dd, yyyy');
interface TDashboardChartProps {
	title?: string;
	chartMeta: {
		current: TChartMeta;
		previous?: TChartMeta;
	};
	dateChartInterval: string[];
	points: { current: TChartValues; previous: TChartValues };
	partialTriggerIntervalValue?: string;
	periodsDiffInDays: number;
	groupingType: 'daily' | 'weekly' | 'monthly';
}

export const DashboardChart: React.FC<TDashboardChartProps> = React.memo(
	({
		title,
		chartMeta,
		dateChartInterval,
		points,
		partialTriggerIntervalValue,
		periodsDiffInDays,
		groupingType,
	}) => {
		const lineSlotElement = useMemo(
			() =>
				getCustomLineSlot(CustomAnimatedLine, {
					partialTriggerIntervalValue,
					currentPeriodSeriesId,
				}),
			[partialTriggerIntervalValue],
		);

		const tooltipSlotElement = getCustomTooltipSlot(CustomChartAxisTooltip, {
			title,
			getFormattedDateList: (currentDateTs: number): string[] | undefined => {
				const currentDate = fromUnixTime(millisecondsToSeconds(currentDateTs));
				const previousDate = addDays(currentDate, -periodsDiffInDays);
				if (groupingType === 'daily') {
					return [formatDateToString(currentDate), formatDateToString(previousDate)];
				}

				if (groupingType === 'weekly') {
					return [currentDate, previousDate].map((date) =>
						[formatDateToString(date), formatDateToString(addDays(addWeeks(date, 1), -1))].join(
							' - ',
						),
					);
				}

				if (groupingType === 'monthly') {
					return [currentDate, previousDate].map((date) =>
						[formatDateToString(date), formatDateToString(addDays(addMonths(date, 1), -1))].join(
							' - ',
						),
					);
				}

				return undefined;
			},
			formatValue: (val) =>
				chartMeta.current.type === 'money'
					? `$${getFormattedAmount(getAmountFromCents(val))}`
					: getFormattedAmount(val, 0),
		});

		const formatXAxisVal = (val: number) => {
			const firstDate = new Date(dateChartInterval[0]).getTime();

			const lastDate = new Date(dateChartInterval[dateChartInterval.length - 1]).getTime();

			if (val === firstDate || val === lastDate) {
				return format(val, groupingType === 'monthly' ? 'LLL' : 'LLL dd');
			}

			return '';
		};

		return (
			<DashboardChartStack>
				<Box>
					{title && <DashboardChartTitle>{title}</DashboardChartTitle>}

					<DashboardChartValue>{getChartSummaryValue(chartMeta.current)}</DashboardChartValue>

					{chartMeta.previous && (
						<DashboardChartPreviousValue>
							{getChartSummaryValue(chartMeta.previous)} previous period
						</DashboardChartPreviousValue>
					)}
				</Box>

				<ChartBox>
					<CustomLineChart
						dates={dateChartInterval}
						values={points.current}
						previousValues={points.previous}
						lineSlotElement={lineSlotElement}
						tooltipSlotElement={tooltipSlotElement}
						xAxisValueFormatter={formatXAxisVal}
					/>
				</ChartBox>
			</DashboardChartStack>
		);
	},
);

DashboardChart.displayName = 'DashboardChart';
