import { CustomDateRangePicker } from '@shared/components/date-range-calendar';
import {
	DateRangeFilterContainer,
	ReportFiltersContainer,
} from '@shared/components/report-filters-container';
import { AidList } from '@entities/aid-list';
import { mapSourcesFromBackend } from '@shared/mappers/map-sources-from-backend';
import { DropdownSelect } from '@shared/components/dropdown-select';

import { type TReportFiltersProps } from './types';

export const ReportFilters: React.FC<TReportFiltersProps> = ({
	dateRange,
	setDateRange,
	setSelectedAids,
	selectedSources,
	setSelectedSources,
	sourcesOptions,
}) => (
	<ReportFiltersContainer>
		<DateRangeFilterContainer>
			<CustomDateRangePicker value={dateRange} setValue={setDateRange} />
		</DateRangeFilterContainer>
		<AidList onSelect={setSelectedAids} theme="classic" />
		{sourcesOptions && (
			<DropdownSelect
				dropdownValue={selectedSources}
				onDropdownSelect={setSelectedSources}
				options={mapSourcesFromBackend(sourcesOptions)}
				isMultiple
				placeholder="Sources"
				innerTitle="Sources"
				resetButton
			/>
		)}
	</ReportFiltersContainer>
);
