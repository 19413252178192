export const getChartTitle = (apiChartTitle: string) => {
	switch (apiChartTitle) {
		case 'clicksChart':
			return 'Clicks';
		case 'epcChart':
			return 'EPC';
		case 'estimatedRevenueChart':
			return 'Est. Revenue';
		default:
			return undefined;
	}
};
