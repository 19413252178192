import { parseISO } from 'date-fns';

import { type TChartDashboardPoint } from '../../../api';

export const findMatchingPoint = (
	intervalDate: Date,
	points: TChartDashboardPoint[],
	compareFunc: (d1: Date, d2: Date) => boolean,
) => {
	const matchingPoint = points.find((point) => compareFunc(parseISO(point.dateTime), intervalDate));
	return matchingPoint ?? null;
};
