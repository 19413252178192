import { type AnimatedLineProps } from '@mui/x-charts/LineChart';
import { getUnixTime } from 'date-fns';

import { type TCustomAnimatedLineProps } from '../model';

export const getCustomLineSlot = (
	CustomAnimatedLineComponent: React.FC<TCustomAnimatedLineProps>,
	data: {
		partialTriggerIntervalValue?: string;
		currentPeriodSeriesId?: string;
	},
) =>
	function withCustomAnimatedProps(props: AnimatedLineProps) {
		const { partialTriggerIntervalValue, currentPeriodSeriesId } = data;
		const customLineProps = partialTriggerIntervalValue
			? {
					limit: getUnixTime(partialTriggerIntervalValue) * 1000,
					currentPeriodSeriesId,
					sxAfter: {
						strokeDasharray: '10 5',
					},
				}
			: {};
		return <CustomAnimatedLineComponent {...props} {...customLineProps} />;
	};
