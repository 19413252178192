'use client';

import { useEffect, useState, useMemo } from 'react';

import { type TDateRangeValue } from '@shared/components/date-range-calendar';
import { useTableSorting } from '@shared/hooks/use-table-sorting';
import { useTablePagination } from '@shared/hooks/use-table-pagination';
import { mapDateToBackend } from '@shared/mappers/map-date-to-backend';
import { mapSortingOrderToBackend } from '@shared/mappers/map-sorting-order-to-backend';
import { ReportContainer, ReportPaper } from '@shared/components/report-container';
import { ReportPagination } from '@shared/components/report-pagination';
import { CommonReportTable } from '@shared/components/report-table';
import { useAidList } from '@entities/aid-list';
import { type TOverviewReportSources } from '@shared/api/overview-report';
import { type TGeneralMarketingReportFilter } from '@widgets/marketing-general-report/api/types';
import { useSetOptionsOnce } from '@shared/hooks/use-set-options-once';

import { mapTotalsFromBackend } from '../lib/map-totals-from-backend';
import { mapDataToCellData } from '../lib/map-data-to-cell-data';
import { generalMarketingReportHooks } from '../api';
import {
	generalMarketingReportDefaultData,
	generalMarketingReportDefaultDateRange,
	generalMarketingReportDefaultSortingOrder,
	generalMarketingReportDefaultSortingType,
	generalMarketingReportDefaultTotals,
	generalMarketingReportTableColumns,
	type TGeneralMarketingReportColumnId,
} from '../model';

import { ReportFilters } from './report-filters';

export const MarketingGeneralReport: React.FC = () => {
	const { aidList, isAidListSuccess } = useAidList(true);
	const [, setSelectedAidList] = useState<number[]>();
	const [sourcesOptions, setSourcesOptions] = useState<
		TGeneralMarketingReportFilter['source'] | undefined
	>(undefined);
	const [selectedSources, setSelectedSources] = useState<TOverviewReportSources>([]);

	const [dateRange, setDateRange] = useState<TDateRangeValue>(
		generalMarketingReportDefaultDateRange,
	);

	const { sorting, handleSortingChange } = useTableSorting<TGeneralMarketingReportColumnId>({
		defaultSortingOrder: generalMarketingReportDefaultSortingOrder,
	});

	const { page, setPage, handleChangePage, rowsPerPage, handleChangeRowsPerPage } =
		useTablePagination({
			pageRowsCount: 10,
		});

	useEffect(() => {
		if (isAidListSuccess && aidList) {
			setSelectedAidList(aidList.map(({ id }) => id));
		}
	}, [aidList, isAidListSuccess]);

	const {
		data: reportData,
		isLoading,
		isError,
		invalidate,
		fetchStatus,
	} = generalMarketingReportHooks.useGetReport(
		{
			queries: {
				// aids: selectedAidList ?? Array.from(initialAidsSet),
				dateFrom: mapDateToBackend(dateRange[0]),
				dateTo: mapDateToBackend(dateRange[1]),
				orderBy: generalMarketingReportDefaultSortingType,
				orderDirection: mapSortingOrderToBackend(sorting.date),
			},
		},
		{ enabled: !dateRange.includes(null) && isAidListSuccess, staleTime: 10_000 },
	);

	const data = useMemo(
		() => reportData?.data ?? generalMarketingReportDefaultData,
		[reportData?.data],
	);

	const totals = useMemo(
		() => reportData?.totals ?? generalMarketingReportDefaultTotals,
		[reportData?.totals],
	);

	useEffect(() => {
		setPage(0);
	}, [reportData, setPage]);

	// Specific case when manager role user has choosen no aids
	const noAidsSelected = aidList && fetchStatus === 'idle' && isLoading;
	const cellData = useMemo(() => mapDataToCellData(data), [data]);
	const totalsData = useMemo(() => mapTotalsFromBackend(totals), [totals]);
	useSetOptionsOnce(reportData?.filter.source, setSourcesOptions);
	return (
		<ReportContainer>
			<ReportPaper>
				<ReportFilters
					dateRange={dateRange}
					setDateRange={setDateRange}
					setSelectedAids={setSelectedAidList}
					sourcesOptions={sourcesOptions}
					selectedSources={selectedSources}
					setSelectedSources={setSelectedSources}
				/>

				<CommonReportTable<TGeneralMarketingReportColumnId>
					data={cellData}
					totals={totalsData}
					page={page}
					rowsPerPage={rowsPerPage}
					sorting={sorting}
					onSortingChange={handleSortingChange}
					columns={generalMarketingReportTableColumns}
					isLoading={isLoading}
					isError={isError}
					invalidate={invalidate}
					isEmpty={noAidsSelected}
				/>
			</ReportPaper>
			<ReportPagination
				totalCount={data.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</ReportContainer>
	);
};
