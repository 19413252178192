import { AnimatedLine } from '@mui/x-charts/LineChart';
import { useChartId, useDrawingArea, useXScale } from '@mui/x-charts/hooks';

import { type TCustomAnimatedLineProps } from '../model';

export const CustomAnimatedLine: React.FC<TCustomAnimatedLineProps> = ({
	limit,
	sxBefore,
	sxAfter,
	ownerState,
	currentPeriodSeriesId,
	...restProps
}) => {
	const { top, bottom, height, left, width } = useDrawingArea();
	const scale = useXScale();
	const chartId = useChartId();

	if (limit === undefined || ownerState.id !== currentPeriodSeriesId) {
		return <AnimatedLine {...restProps} ownerState={ownerState} />;
	}

	const limitPosition = scale(limit); // Convert value to x coordinate.

	if (limitPosition === undefined) {
		return <AnimatedLine {...restProps} ownerState={ownerState} />;
	}

	const clipIdleft = `${chartId}-${ownerState.id}-line-limit-${limit}-1`;
	const clipIdRight = `${chartId}-${ownerState.id}-line-limit-${limit}-2`;
	return (
		<>
			{/* Clip to show the line before the limit */}
			<clipPath id={clipIdleft}>
				<rect x={left} y={0} width={limitPosition - left} height={top + height + bottom} />
			</clipPath>
			{/* Clip to show the line after the limit */}
			<clipPath id={clipIdRight}>
				<rect
					x={limitPosition}
					y={0}
					width={left + width - limitPosition}
					height={top + height + bottom}
				/>
			</clipPath>
			<g clipPath={`url(#${clipIdleft})`}>
				<AnimatedLine {...restProps} ownerState={ownerState} sx={sxBefore} />
			</g>
			<g clipPath={`url(#${clipIdRight})`}>
				<AnimatedLine {...restProps} ownerState={ownerState} sx={sxAfter} />
			</g>
		</>
	);
};
