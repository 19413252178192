import { differenceInDays, differenceInMonths } from 'date-fns';

import { type TDateRangeValue } from '@shared/components/date-range-calendar';

import { type TGroupingType } from '../model';

export const getGroupingListByDateRange = (dateRange: TDateRangeValue): TGroupingType[] => {
	const [startDate, endDate] = dateRange;
	if (startDate === null || endDate === null) {
		return ['monthly'];
	}
	const daysDifference = differenceInDays(endDate, startDate);
	const monthsDifference = differenceInMonths(endDate, startDate);

	if (daysDifference <= 14) {
		return ['daily'];
	}
	if (daysDifference > 14 && monthsDifference < 2) {
		return ['daily', 'weekly'];
	}
	if (monthsDifference >= 2 && monthsDifference < 3) {
		return ['daily', 'weekly', 'monthly'];
	}
	if (monthsDifference >= 3 && monthsDifference < 6) {
		return ['weekly', 'monthly'];
	}

	return ['monthly'];
};
