import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import { axiosInstance } from '@shared/api/axios-instance';
import { BASE_URL } from '@shared/api/constants';

import { dashboardApi } from './api';

export const dashboardApiClient = new Zodios(BASE_URL, dashboardApi, { axiosInstance });

export const dashboardApiHooks = new ZodiosHooks('dashboard', dashboardApiClient);

export * from './types';
