import { type TGroupingType } from '@features/select-grouping-type';

import { type TPathGroupType } from '../api';

export const mapGroupTypeToPathParam = (groupingType: TGroupingType): TPathGroupType => {
	switch (groupingType) {
		case 'weekly':
			return 'by-week';
		case 'monthly':
			return 'by-month';
		case 'daily':
		default:
			return 'by-day';
	}
};
