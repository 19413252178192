import { useRef } from 'react';

import { type TDateRangeValue } from '@shared/components/date-range-calendar';

import { getOptionsListByDateRange } from './get-options-list-by-date-range';

export const useGroupingTypeOptionsList = (selectedDateRange: TDateRangeValue) => {
	const optionsListRef = useRef(getOptionsListByDateRange(selectedDateRange));
	const prevDateRange = useRef(selectedDateRange);
	const isOptionsListNewRef = useRef(false);

	const hasDateRangeBeenChanged =
		selectedDateRange.some((dateRangeItem, idx) => dateRangeItem !== prevDateRange.current[idx]) &&
		!selectedDateRange.includes(null);

	if (hasDateRangeBeenChanged) {
		optionsListRef.current = getOptionsListByDateRange(selectedDateRange);
		isOptionsListNewRef.current = true;
		prevDateRange.current = selectedDateRange;
	}
	return { optionsList: optionsListRef.current, isOptionsListNewRef };
};
