import { format } from 'date-fns';

import { SelectGroupingType, type TGroupingType } from '@features/select-grouping-type';
import {
	CustomDateRangePicker,
	getPreviousDateRange,
	type TDateRangeValue,
} from '@shared/components/date-range-calendar';

import {
	ContentHeaderBox,
	DashboardLegendBox,
	DashboardLegendItemBox,
	DashboardLegendItemTypography,
	PreliminaryDataIcon,
	PreviousPeriodIcon,
	ChosenPeriodIcon,
	GroupingBox,
} from './styles';

interface TDashboardProps {
	dateRange: TDateRangeValue;
	groupingType: TGroupingType[];
	onChangeDatePickerValue: (dateRange: TDateRangeValue) => void;
	onDropdownSelect: (selectedValue: TGroupingType[]) => void;
}
export const DashboardHeader: React.FC<TDashboardProps> = ({
	dateRange,
	groupingType,
	onChangeDatePickerValue,
	onDropdownSelect,
}) => {
	const previousDateRange = getPreviousDateRange(dateRange);

	const prevPeriodString = previousDateRange
		.map((date) => format(date ?? new Date(), 'LLL dd'))
		.join(' – ');

	return (
		<ContentHeaderBox justifyContent="space-between">
			<GroupingBox>
				<CustomDateRangePicker value={dateRange} setValue={onChangeDatePickerValue} />

				<SelectGroupingType
					onSelect={onDropdownSelect}
					groupingTypeValue={groupingType}
					selectedDateRange={dateRange}
				/>
			</GroupingBox>

			<DashboardLegendBox>
				<DashboardLegendItemBox>
					<PreliminaryDataIcon />
					<DashboardLegendItemTypography>Preliminary data</DashboardLegendItemTypography>
				</DashboardLegendItemBox>

				<DashboardLegendItemBox>
					<ChosenPeriodIcon />
					<DashboardLegendItemTypography>Chosen Period</DashboardLegendItemTypography>
				</DashboardLegendItemBox>

				<DashboardLegendItemBox>
					<PreviousPeriodIcon />

					<DashboardLegendItemTypography>
						Previous Period ({prevPeriodString})
					</DashboardLegendItemTypography>
				</DashboardLegendItemBox>
			</DashboardLegendBox>
		</ContentHeaderBox>
	);
};
