'use client';

import { useState } from 'react';

import { type TDateRangeValue } from '@shared/components/date-range-calendar';
import { type TGroupingType, getOptionsListByDateRange } from '@features/select-grouping-type';

import { getInitialDateRange } from '../lib';

import { DashboardCharts } from './dashboard-charts';
import { DashboardHeader } from './dashboard-header';

const initialDateRange = getInitialDateRange();
const initialGroupingType = getOptionsListByDateRange(initialDateRange)[0].value;

export const AffiliateDashboard: React.FC = () => {
	const [dateRange, setDateRange] = useState<TDateRangeValue>(initialDateRange);

	const [groupingType, setGroupingType] = useState<TGroupingType[]>([initialGroupingType]);

	return (
		<>
			<DashboardHeader
				dateRange={dateRange}
				groupingType={groupingType}
				onChangeDatePickerValue={setDateRange}
				onDropdownSelect={setGroupingType}
			/>

			<DashboardCharts dateRange={dateRange} groupingType={groupingType} />
		</>
	);
};
