import { Paper, Divider } from '@mui/material';

import { type TCustomAxisTooltipProps } from '../model';

import {
	CustomTooltipTitle,
	CustomTooltipListItemText,
	CustomTooltipList,
	CustomTooltipListItem,
	CustomTooltipDateLabel,
	CustomTooltipValueLabel,
	ChosenPeriodIcon,
	PreviousPeriodIcon,
} from './styles';

export const CustomChartAxisTooltip: React.FC<TCustomAxisTooltipProps> = ({
	title,
	formatValue,
	getFormattedDateList,
	...restProps
}) => {
	const xAxisValue = restProps.axisData.x?.value;
	const index = restProps.axis.data?.findIndex((timestamp) => timestamp === xAxisValue);
	if (index === -1 || typeof index === 'undefined') {
		return null;
	}
	const currentPeriodValue = restProps.series[1].data[index];
	const previousPeriodValue = restProps.series[0].data[index];
	const [currentDateStr, previousDateStr] = getFormattedDateList(Number(xAxisValue)) || [];
	return (
		<Paper>
			<CustomTooltipTitle>{title}</CustomTooltipTitle>
			<Divider />
			<CustomTooltipList>
				{currentPeriodValue !== null && (
					<CustomTooltipListItem>
						<CustomTooltipListItemText
							primary={
								<CustomTooltipValueLabel component="span">
									<ChosenPeriodIcon />
									{formatValue && formatValue(Number(currentPeriodValue))}
								</CustomTooltipValueLabel>
							}
							secondary={
								<CustomTooltipDateLabel component="span">{currentDateStr}</CustomTooltipDateLabel>
							}
						/>
					</CustomTooltipListItem>
				)}
				{previousPeriodValue !== null && (
					<CustomTooltipListItem>
						<CustomTooltipListItemText
							primary={
								<CustomTooltipValueLabel component="span">
									<PreviousPeriodIcon />
									{formatValue && formatValue(Number(previousPeriodValue))}
								</CustomTooltipValueLabel>
							}
							secondary={
								<CustomTooltipDateLabel component="span">{previousDateStr}</CustomTooltipDateLabel>
							}
						/>
					</CustomTooltipListItem>
				)}
			</CustomTooltipList>
		</Paper>
	);
};
