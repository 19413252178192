import z from 'zod';

export const dashboardRequestSchema = {
	dateFrom: z.string(),
	dateTo: z.string(),
};

export const dashboardPointSchema = z.object({
	dateTime: z.string().datetime({ offset: true }),
	value: z.number(),
	isPartialData: z.boolean(),
});

export const dashboardChartSchema = z.object({
	points: z.array(dashboardPointSchema),
	summary: z.number(),
	type: z.union([z.literal('money'), z.literal('number')]),
});

export const dashboardResponseSchema = z.object({
	charts: z.object({
		clicksChart: dashboardChartSchema,
		epcChart: dashboardChartSchema,
		estimatedRevenueChart: dashboardChartSchema,
	}),
});

export const groupTypeSchema = z.union([
	z.literal('by-day'),
	z.literal('by-week'),
	z.literal('by-month'),
	z.literal('by-year'),
]);
export const dashboardErrorResponseSchema = z.object({
	message: z.string(),
});
