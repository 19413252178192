import { type ChartsAxisContentProps } from '@mui/x-charts';

import { type TCustomAxisTooltipPayload, type TCustomAxisTooltipProps } from '../model';

export const getCustomTooltipSlot = (
	CustomTooltipComponent: React.FC<TCustomAxisTooltipProps>,
	payload: TCustomAxisTooltipPayload,
) =>
	function withCustomTooltipProps(props: ChartsAxisContentProps) {
		return <CustomTooltipComponent {...props} {...payload} />;
	};
