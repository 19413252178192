'use client';

import { Box, Stack } from '@mui/material';

import { DashboardChartStack, DashboardChartTitle, DashboardChartValue, Skeleton } from './styles';

export const DashboardChartSkeleton: React.FC = () => (
	<DashboardChartStack>
		<Box>
			<DashboardChartTitle>
				<Skeleton animation="wave" width={195} />
			</DashboardChartTitle>
			<DashboardChartValue>
				<Skeleton animation="wave" width={104} />
			</DashboardChartValue>
		</Box>
		<Stack height={250} justifyContent="flex-end">
			<Stack direction="row" justifyContent="space-between">
				<DashboardChartTitle sx={{ margin: 0 }}>
					<Skeleton animation="wave" width={62} />
				</DashboardChartTitle>
				<DashboardChartTitle sx={{ margin: 0 }}>
					<Skeleton animation="wave" width={62} />
				</DashboardChartTitle>
			</Stack>
		</Stack>
	</DashboardChartStack>
);
